import gql from 'graphql-tag'
export default gql`query strengthClassQuery($id: Int!) {
  strengthClass(id: $id) {
    code
    description
    cilinderPressureStrength
    cubePressureStrength
    bendTensileStrength
    samplePerVolume
    sampleMinimum
    sampleMaximum
    id
  }
}`