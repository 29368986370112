import React, { useCallback, useEffect, useState } from 'react'
import { Cube, CubeType } from '../../types'
import { Stepper, Icon, InputAdornment } from '@material-ui/core'
import { PressureIcon, PenetrationIcon } from '../../icons'
import CubeDetails from './CubeDetails'
import CubeEditStep, { Step } from './CubeEditStep'
import CubeDensityStep from './CubeDensityStep'
import CubePressureStep from './CubePressureStep'
import CubeRemarksStep from './CubeRemarksStep'
import { ReceiptWithCubeTestAndCubeWithCubeNumbers, CubeWithCubeNumber } from '../../containers/PressureStrength'

function getSteps(cube: Cube) {
  return [
    {
      title: 'Volumieke massa',
      icon: <Icon color="inherit">archive</Icon>,
      Component: CubeDensityStep
    },
    ...(cube.type === CubeType.Pressure || cube.type === CubeType.Ripeness) ? [{
      title: 'Druksterkte',
      icon: <PressureIcon color="inherit" />,
      Component: CubePressureStep
    }] :
      [{
        title: 'Indringing',
        icon: <PenetrationIcon color="inherit" />,
        label: 'Indringing',
        key: 'penetration',
        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        decimalScale: 0
      }],
    ...(cube.type === CubeType.Ripeness) ? [{
      title: 'Gewogen rijpheid',
      icon: <PenetrationIcon color="inherit" />,
      label: 'Graad uren',
      key: 'temperature',
      endAdornment: <InputAdornment position="end">&deg;Ch</InputAdornment>,
      decimalScale: 1
    }] : [],
    {
      title: 'Opmerkingen toevoegen',
      label: "Opmerkingen",
      key: 'remarks',
      icon: <Icon>comment</Icon>,
      optional: true,
      Component: CubeRemarksStep
    }
  ] as Step[]
}

const CubeEdit: React.FC<{ receipt: ReceiptWithCubeTestAndCubeWithCubeNumbers, cube: CubeWithCubeNumber, onChange: (receipt: ReceiptWithCubeTestAndCubeWithCubeNumbers) => void }> = ({ onChange, receipt, ...props }) => {
  const [cube, setCube] = useState(props.cube)

  useEffect(() => {
    setCube(props.cube)
  }, [props.cube])

  const handleChange = useCallback((cube: CubeWithCubeNumber) => {
    setCube({ ...cube })
    const index = (receipt.cubeTest.cubes as CubeWithCubeNumber[]).findIndex(c => c.hash === cube.hash)
    if (index >= 0) {
      receipt.cubeTest.cubes[index] = cube
      onChange && onChange(receipt)
    }
  }, [onChange, receipt])

  return (
    <div style={{ height: '100%', overflow: 'auto', flex: 1 }}>
      <Stepper style={{ background: 'none' }} orientation="vertical">
        {getSteps(cube).map((step, k) => <CubeEditStep key={k} step={step} cube={cube} receipt={receipt} onChange={handleChange} />)}
      </Stepper>
      <CubeDetails receipt={receipt} />
    </div>
  )
}

export default CubeEdit;
