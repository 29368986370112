import gql from 'graphql-tag'
export default gql`query sieveSetQuery($id: Int!) {
  sieveSet(id: $id) {
    code
    description
    type
    sieveSteps {
      sieveSize {
        code
        size
        id
      }
    }
    id
  }
}`