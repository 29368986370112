import gql from 'graphql-tag'
export default gql`mutation updateStrengthClassMutation($strengthClassId: Int!, $strengthClass: BaseStrengthClass!) {
  updateStrengthClass(strengthClassId: $strengthClassId, strengthClass: $strengthClass) {
    code
    description
    cilinderPressureStrength
    cubePressureStrength
    bendTensileStrength
    samplePerVolume
    sampleMinimum
    sampleMaximum
    id
  }
}`