import gql from 'graphql-tag'
export default gql`query consistencyTestTypesQuery {
  consistencyTestTypes {
    code
    description
    parameters {
      unit
      label
      placeholder
    }
    id
  }
}`