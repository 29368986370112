import React, { useCallback, useState, Fragment, useEffect, FormEvent, useContext } from 'react'
import { Slide, Dialog, DialogActions, Button, DialogContent, TextField, MenuItem, makeStyles, Theme, Paper, InputBase, IconButton, Icon, LinearProgress, Typography, Grid, Card, CardHeader, ButtonBase, Avatar } from '@material-ui/core'
import DialogTitle from '../DialogTitle'
import { Receipt, Recipe } from '../../types'
import Autocomplete from '../Autocomplete'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Autosuggest from 'react-autosuggest'
import { RECEIPT } from '../../graphql/queries'
import { AppContext } from '../../App'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import NumericTextField from '../NumericTextField'
import { MobileDatePicker } from '@material-ui/pickers'
import gql from 'graphql-tag'

const PARTIAL_RECIPES_QUERY = gql`query {
  recipes {
    id
    recipeName
    revision
    consistencyClass {
      id
      code
      consistencyTestTypes {
        id
        code
        description
        parameters {
          unit
          label
          placeholder
        }
      }
    }
  }
}`

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    position: 'relative'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'none'
  }
}))

const SearchField: React.FC<{ placeholder: string, onChange?: (value: string) => void, onSearch: (value: string) => Promise<void> }> = ({ placeholder, onChange, onSearch }) => {
  const [value, setValue] = useState('')
  const [searching, setSearching] = useState(false)
  const classes = useStyles()
  const handleChange = useCallback((value: string) => {
    setValue(value)
    onChange && onChange(value)
  }, [onChange])
  const handleSearch = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSearching(true)
    await onSearch(value)
    setSearching(false)
  }, [value, onSearch])
  return (
    <form onSubmit={handleSearch}>
      <Paper className={classes.root} elevation={4}>
        <InputBase
          className={classes.input}
          placeholder={placeholder}
          value={value}
          onChange={e => handleChange(e.target.value)}
        />
        <IconButton disabled={value === ''} type="submit" className={classes.iconButton}>
          <Icon>search</Icon>
        </IconButton>
        {searching && <LinearProgress variant="indeterminate" className={classes.progress} />}
      </Paper>
    </form>
  )
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
})

const getSuggestionValue = (recipe: Recipe) => recipe.recipeName

type AddSampleDialogProps = {
  receipts: Receipt[],
  open: boolean,
  onAdd?: (receipt: Receipt, createReceipt: boolean) => void,
  onClose: () => void
}

const AddSampleDialog: React.FC<AddSampleDialogProps> = ({ receipts, open, onAdd, onClose }) => {
  const [suggestions, setSuggestions] = useState([] as Receipt[])
  const [isSearchResult, setIsSearchResult] = useState(false)
  const [manual, setManual] = useState(false)
  const [data, setData] = useState({ testDate: new Date() } as { receiptId?: number, recipe?: Recipe, customer?: string, amount?: number, testDate: Date })
  const { selectedPlant } = useContext(AppContext)
  const valid = Boolean(data.receiptId && data.recipe && data.customer && data.amount)
  const client = useApolloClient()

  useEffect(() => {
    if (open) {
      setData({ testDate: new Date() })
      setManual(false)
      setIsSearchResult(false)
      setSuggestions(receipts.slice(0, 3))
    }
  }, [open, receipts])

  const { data: recipesResult } = useQuery<{ recipes: Recipe[] }>(PARTIAL_RECIPES_QUERY)

  const handleAdd = useCallback((receipt?: Receipt) => {
    onAdd && data.receiptId && onAdd(receipt ? receipt : {
      id: data.receiptId,
      revision: { id: data.recipe?.revision, recipe: data.recipe },
      plant: selectedPlant,
      date: data.testDate,
      customer: data.customer,
      amount: data.amount,
      remarks: ''
    } as Receipt, receipt ? false : true)
  }, [onAdd, data, selectedPlant])

  const getSuggestions = useCallback((value: string) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    return inputLength === 0 || !recipesResult?.recipes ? [] : recipesResult?.recipes.filter(recipe =>
      recipe.recipeName.toLowerCase().indexOf(inputValue) >= 0
    )
  }, [recipesResult])

  const renderSuggestion = useCallback((recipe: Recipe, { query, isHighlighted }: Autosuggest.RenderSuggestionParams) => {
    const matches = match(recipe.recipeName, query)
    const parts = parse(recipe.recipeName, matches)
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map(part => (
            <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    )
  }, [])

  const handleChange = useCallback((key: string, value: string | number | Recipe | Date) => {
    setData({ ...data, [key]: value })
  }, [data])

  const handleSearchChange = useCallback((value: string) => {
    setData({ ...data, receiptId: Number(value) })
    if (value === '') {
      setSuggestions(receipts.slice(0, 3))
      setIsSearchResult(false)
    }
  }, [receipts, data])

  const handleSearch = useCallback(async (value: string) => {
    const result = await client.query({ query: RECEIPT, variables: { receiptId: Number(value) } }) // TODO: refactor to useQuery
    result.data.receipt ? setSuggestions([result.data.receipt]) : setSuggestions([])
    setIsSearchResult(true)
  }, [client])

  return (
    <Dialog open={open} TransitionComponent={Transition} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
      <DialogTitle onClose={onClose}>Monstername toevoegen</DialogTitle>
      <DialogContent>
        {!manual ? (
          <Fragment>
            <SearchField
              placeholder="Zoek bonnummer"
              onSearch={handleSearch}
              onChange={handleSearchChange}
            />
            <Typography variant="body2" gutterBottom={true} color={!isSearchResult || suggestions.length > 0 ? 'initial' : 'error'} component="p" style={{ marginTop: 16 }}>
              {isSearchResult ? suggestions.length > 0 ? 'Zoek resultaat:' : 'Bon niet gevonden' : suggestions.length > 0 ? 'Recente bonnen:' : 'Geen recente bonnen'}
            </Typography>
            {suggestions.length > 0 && (
              <Grid container={true} spacing={2}>
                {suggestions.map((receipt, k) => (
                  <Grid item={true} key={k}>
                    <Card>
                      <ButtonBase onClick={() => handleAdd(receipt)}>
                        <CardHeader
                          avatar={<Avatar><Icon>receipt</Icon></Avatar>}
                          title={receipt.id}
                          subheader={receipt.revision.recipe.strengthClass?.code + ' - ' + receipt.revision.recipe.environmentClasses?.reduce((lowest, ec) => ec.maxWbf < lowest ? ec.maxWbf : lowest, 1)}
                          titleTypographyProps={{
                            align: 'left'
                          }}
                        />
                      </ButtonBase>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
            {suggestions.length === 0 && (
              <Grid container={true} justify="center" style={{ marginTop: 16 }}>
                <Button variant="contained" color="primary" onClick={() => setManual(true)}>Handmatig invoeren</Button>
              </Grid>
            )}
          </Fragment>
        ) : (
            <Fragment>
              <TextField
                label="Bonnummer"
                variant="outlined"
                margin="normal"
                fullWidth={true}
                value={data.receiptId || ''}
                onChange={e => handleChange('receiptId', Number(e.target.value))}
              />
              <Autocomplete
                value={data.recipe ? data.recipe.recipeName : ''}
                label="Kies een recept"
                variant="outlined"
                margin="normal"
                fullWidth={true}
                onChange={(recipe) => handleChange('recipe', recipe)}
                getSuggestions={getSuggestions}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
              />
              <TextField
                label="Klant"
                variant="outlined"
                margin="normal"
                fullWidth={true}
                value={data.customer || ''}
                onChange={e => handleChange('customer', e.target.value)}
              />
              <NumericTextField
                label="Aantal kuub"
                margin="normal"
                fullWidth={true}
                value={typeof data.amount !== 'undefined' && data.amount !== null ? data.amount : ''}
                onChange={e => handleChange('amount', Number(e.target.value))}
                maximumFractionDigits={2}
              />
              <MobileDatePicker
                label="Datum monstername"
                value={data.testDate}
                onChange={e => handleChange('testDate', e ? e : new Date())}
                renderInput={({ helperText, ...props }) => <TextField {...props} fullWidth={true} variant="outlined" margin="normal" />}
                inputFormat="D MMMM YYYY"
                maxDate={new Date()}
                disableCloseOnSelect={false}
                cancelText="Annuleren"
              />
            </Fragment>
          )}
      </DialogContent>
      <DialogActions>
        {manual && <Button onClick={() => { setManual(false); handleSearchChange('') }}>Terug</Button>}
        <span style={{ flex: 1 }} />
        <Button onClick={onClose}>Annuleren</Button>
        {manual && <Button onClick={() => handleAdd()} disabled={!valid} color="secondary">Toevoegen</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default AddSampleDialog
