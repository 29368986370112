import gql from 'graphql-tag'
export default gql`mutation createGradingCurveMutation($gradingCurve: BaseGradingCurve!) {
  createGradingCurve(gradingCurve: $gradingCurve) {
    code
    description
    type
    sieveSteps {
      min
      max
      maxAlt
      sieveSize {
        code
        size
        id
      }
    }
    id
    dMax
  }
}`