import gql from 'graphql-tag'
export default gql`query resourcesQuery {
  resources {
    ... on Addition {
      name
      type
      articleCode
      brand
      supplier
      price
      density
      chloridePercentage
      alkaliPercentage
      plants {
        name
        id
      }
      id
      absorption
      moisture
      isSand
      sieveTest {
        startingWeight
        sieveSet {
          code
          description
          type
          sieveSteps {
            sieveSize {
              code
              size
              id
            }
          }
          id
        }
        sieveSteps {
          sieveSize {
            code
            size
            id
          }
          restWeight
        }
        finenessModulus
      }
      gradingCurve {
        code
        description
        type
        sieveSteps {
          min
          max
          maxAlt
          sieveSize {
            code
            size
            id
          }
        }
        id
        dMax
      }
    }
    ... on Cement {
      name
      type
      articleCode
      brand
      supplier
      price
      density
      chloridePercentage
      alkaliPercentage
      plants {
        name
        id
      }
      id
      strengthWeek
      strengthNorm
    }
    ... on Excipient {
      name
      type
      articleCode
      brand
      supplier
      price
      density
      chloridePercentage
      alkaliPercentage
      plants {
        name
        id
      }
      id
      dosingMethod
      mainEffect
      secondaryEffect
      absorption
      moisture
    }
    ... on Extra {
      name
      type
      articleCode
      brand
      supplier
      price
      density
      chloridePercentage
      alkaliPercentage
      plants {
        name
        id
      }
      id
      percentageFine
      absorption
      moisture
    }
    ... on Filler {
      name
      type
      articleCode
      brand
      supplier
      price
      density
      chloridePercentage
      alkaliPercentage
      plants {
        name
        id
      }
      id
      cementKFactors {
        kFactor
        cement {
          name
          type
          articleCode
          brand
          supplier
          price
          density
          chloridePercentage
          alkaliPercentage
          plants {
            name
            id
          }
          id
          strengthWeek
          strengthNorm
        }
      }
    }
    ... on Water {
      name
      type
      articleCode
      brand
      supplier
      price
      density
      chloridePercentage
      alkaliPercentage
      plants {
        name
        id
      }
      id
      siltPercentage
    }
  }
}`