import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";
import "material-design-icons/iconfont/material-icons.css";
import moment from "moment";
import "moment/locale/nl";
import apolloClient from "./config/apolloClient";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { ApolloProvider } from "@apollo/react-hooks";
import materialTheme from "./config/materialTheme";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { WsClientProvider } from "./WsClientContext";
import { setLocale } from "react-numberinput-formatter";
import MomentUtils from '@material-ui/pickers/adapter/moment'
import { LocalizationProvider } from '@material-ui/pickers'
import {Settings} from "./config/settings";

moment.locale("nl");
setLocale("nl");

export const LOCAL_STORAGE_KEY = "token";

declare const Sentry: any;
if (Settings.environment==='production') {
  Sentry.init({
    dsn: "https://fc808149e6b54ac3abcbf499b9f4c5f2@sentry.io/2733859"
  });
}

const { client, wsClient } = apolloClient({ LOCAL_STORAGE_KEY });
const theme = materialTheme();

ReactDOM.render(
  <ApolloProvider client={client}>
    <WsClientProvider wsClient={wsClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <CssBaseline />
          <Router basename={"/"}>
            <App />
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </WsClientProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
