import React, { useState, useEffect, createContext, useCallback, Fragment } from 'react'
import { Switch, Route, Redirect, withRouter, RouteComponentProps } from 'react-router-dom'
import { Loading, SetupDialog, AppToolbar } from './components'
import { User, Plant } from './types'
import { LOCAL_STORAGE_KEY } from '.'
import { ApolloQueryResult } from 'apollo-boost'
import { Sampling, PressureStrength, Reports } from './containers'
import {Toolbar, Grid, Box} from '@material-ui/core'
import { useApolloClient } from '@apollo/react-hooks'
import queryString from 'query-string'
import jwt from 'jsonwebtoken'
import Unauthorized from './containers/Unauthorized'
import NotFound from './containers/NotFound'
import { USER } from './graphql/queries'
import {Settings} from "./config/settings";
import LoginForm from "./components/login";

export enum Tasks {
  SAMPLE = 'sample',
  PRESSURE = 'pressure',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports'
}

let redirecting = false;

type TAppContextValue = {
  selectedPlant: Plant | undefined,
  setToken: (token: string) => void,
  selectPlant: (plant: Plant | undefined, remember?: boolean) => void,
  selectTask: (task: Tasks.SAMPLE | Tasks.PRESSURE | Tasks.DASHBOARD | Tasks.REPORTS, remember?: boolean) => void,
  logout: () => void,
  isSaving: boolean,
  setIsSaving: (isSaving: boolean) => void,
  isSaved: boolean,
  setIsSaved: (isSaved: boolean) => void
}

export const AppContext = createContext({} as TAppContextValue);

const App: React.FC<RouteComponentProps> = ({ children, location, history, match }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [loading, setLoading] = useState(undefined as string | undefined)
  const [token, setToken] = useState(undefined as string | undefined)
  const [user, setUser] = useState(undefined as User | undefined)
  const [task, setTask] = useState(undefined as Tasks | undefined)
  const [plant, setPlant] = useState(undefined as Plant | undefined)
  const [redirect, setRedirect] = useState(false)
  const client = useApolloClient();
  //const errors = useQuery(GET_CLIENT_ERRORS) TODO: deal with errors???
  // const unauthorized = errors && JSON.parse(errors.data.networkError) && JSON.parse(errors.data.networkError).statusCode === 401
  const unauthorized = false

  // see if there is an old token that hasnt expired, otherwise redirect to login
  useEffect(() => {
    let oldToken = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (window.location.search && window.location.search.indexOf('token=') >= 0 && !redirecting) {
      const { token, ...rest } = queryString.parse(window.location.search)
      if (token) {
        localStorage.setItem(LOCAL_STORAGE_KEY, token as string)
        oldToken = token as string
        redirecting = true
        const q = queryString.stringify(rest)
        const redirect = window.location.pathname + (q.length > 0 ? '?' + q : '')
        setTimeout(() => {
          history.push(redirect)
        })
      }
    }
    const decoded = jwt.decode(oldToken || '') as any
    if (oldToken && decoded.exp && Date.now() < decoded.exp * 1000) {
      setToken(oldToken)
    } else {
      const url = new URL(window.location as any)
      const href = (Settings.URLs.client || url.origin) + '/login'
      if (href !== window.location.href) {
        window.location.href = href
      }
    }
  }, [history])

  // get user info when token changes
  useEffect(() => {
    client && token && (async () => {
      setLoading("Inloggen...");

      const tokenData=getValidTokenData(token)
      if(!tokenData)
        redirectLogin();
      else {
        let user={
          created_at: undefined,
          email: "",
          email_verified: false,
          last_ip: "",
          last_login: undefined,
          logins_count: 0,
          nickname: "",
          picture: "",
          updated_at: undefined,
          user_id: tokenData.sub,
          username: tokenData.user,
          name: tokenData.user
        }
        setUser(user);
        setLoading(undefined);
        return user;
      }
    })();
  }, [token, client]);

  // get initial state from local storage
  useEffect(() => {
    let oldTask = localStorage.getItem('task');
    const tasks = [Tasks.SAMPLE, Tasks.PRESSURE, Tasks.DASHBOARD, Tasks.REPORTS];
    const taskMatch = tasks.find(task => location.pathname.indexOf('/' + task) === 0);
    taskMatch && (oldTask = taskMatch);
    if (oldTask) {
      setTask(oldTask as Tasks);
      if (location.pathname.indexOf('/' + oldTask) !== 0) {
        setRedirect(true);
        setTimeout(() => {
          setRedirect(false);
        });
      }
    }
    const oldPlant = localStorage.getItem('plant');
    oldPlant && setPlant(JSON.parse(oldPlant) as Plant);
  }, [location]);

  function getValidTokenData(token: string) {
    const decodedToken=jwt.decode(token || '') as any

    if(decodedToken===null) return false; // No token found
    if(decodedToken.user===undefined) return false; // No user data found
    if(decodedToken.exp && Date.now() > decodedToken.exp * 1000) return false; // Token expired

    return decodedToken;
  }

  function redirectLogin() {
    const url = new URL(window.location as any)
    const href = url.origin + '/login'
    if (href !== window.location.href) {
      // Redirect to login page
      window.location.href = href
    }
    return;
  }

  const selectPlant = useCallback((plant: Plant | undefined, remember: boolean = false) => {
    remember && plant ? localStorage.setItem('plant', JSON.stringify(plant)) : localStorage.removeItem('plant');
    localStorage.removeItem('task');
    setPlant(plant);
  }, [setPlant]);

  const selectTask = useCallback((task: Tasks, remember: boolean = false) => {
    remember && localStorage.setItem('task', task);
    setTask(task);
    setRedirect(true);
    setTimeout(() => {
      setRedirect(false);
    });
  }, [setTask]);

  const logout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
    localStorage.removeItem('task')
    localStorage.removeItem('plant')
    const url = new URL(window.location as any)
    window.location.href = (Settings.URLs.client || url.origin) + '/logout'
  }, []);

  return <AppContext.Provider value={{ selectedPlant: plant, setToken, selectPlant, selectTask, logout, isSaving, setIsSaving, isSaved, setIsSaved }}>
    {loading ? <Loading /> : ( <div>
      <Switch>
        {/* @ts-ignore */}
        {!user && (
        /* @ts-ignore */
        <Box display="flex" flexDirection="column" width="100%" height="100%">
          <Box flex={1} overflow="auto">
            {/* @ts-ignore */}
          <Switch>
            {/* @ts-ignore */}
            <Route path="/login" component={LoginForm} />
          </Switch>
          </Box>
        </Box>
      )}
      </Switch>
      <Switch>
        {user && (<Fragment>
          <AppToolbar user={user} plant={plant} />
          {user && plant && <Grid container={true} direction="column" style={{ width: '100%', height: '100%' }} wrap="nowrap">
            <Toolbar />
            {unauthorized && location.pathname !== '/unauthorized' && <Redirect to="/unauthorized" />}
            {redirect && task && <Redirect to={'/' + task} />}
            {!redirect && task && (<Switch>
              <Route path={'/' + Tasks.SAMPLE + '/:cubeNumber?'} component={Sampling} />
              <Route path={'/' + Tasks.PRESSURE + '/:cubeHash?'} component={PressureStrength} />
              <Route path={'/' + Tasks.REPORTS + '/:type?/:period?/:period2?'} component={Reports} />
              <Route path="/" exact={true}>
                {task && <Redirect to={'/' + task} />}
              </Route>
              <Route path="/unauthorized" component={Unauthorized} />
              <Route component={NotFound} />
            </Switch>)}
          </Grid>}
          <SetupDialog
            open={!plant || !task}
            title={!plant ? 'Selecteer een centrale' : 'Selecteer een taak'}
            subTitle={!plant ? 'Waar worden de werkzaamheden uitgevoerd?' : 'Welke taak wilt u nu uitvoeren?'}
            step={!plant ? 'plant' : 'task'}
          />
        </Fragment>)}
      </Switch>
        </div>
    )}
  </AppContext.Provider>
}

export default withRouter(App);
