import gql from 'graphql-tag'
export default gql`query consistencyClassQuery($id: Int!) {
  consistencyClass(id: $id) {
    code
    description
    minRequirement
    maxRequirement
    minDeviation
    maxDeviation
    maxDeviationsAllowed
    consistencyTestTypes {
      code
      description
      parameters {
        unit
        label
        placeholder
      }
      id
    }
    id
  }
}`