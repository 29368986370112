export const Settings ={
  environment: 'production', // 'development' or 'production'
  company: 'abcreceptuur',
  URLs: {
    appLogin: 'http://abctest.jbmsoftware.nl:5123/recipe-app/post/login',
    server: 'http://abctest.jbmsoftware.nl:4000',
    client: '',
    recipeApp: 'http://abc.receptuur.jbmsoftware.nl/',
    orderApp: 'http://abc.jbmsoftware.nl/',
  }
}
