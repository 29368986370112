import gql from 'graphql-tag'
export default gql`mutation updateSieveSetMutation($sieveSetId: Int!, $sieveSet: BaseSieveSet!) {
  updateSieveSet(sieveSetId: $sieveSetId, sieveSet: $sieveSet) {
    code
    description
    type
    sieveSteps {
      sieveSize {
        code
        size
        id
      }
    }
    id
  }
}`