import { Icon, IconButton, List, ListItem, ListItemText, Popover, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { Fragment, useMemo } from 'react'
import { format } from 'react-numberinput-formatter'
import { useHistory } from 'react-router-dom'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import { CubeTest, EnvironmentClass } from '../../types'
import { calculateWcf } from '../Sampling/CubeTestWcfStep'

const GroupRow: React.FC<{ showDeviations: boolean, group: { environmentClasses: EnvironmentClass[], maxWbf: number, tests: Array<{ wcf: number, cubeTest: CubeTest }> } }> = ({ showDeviations, group: { environmentClasses, maxWbf, tests } }) => {
  const [anchorElDeviations, setAnchorElDeviations] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElOutsideMax, setAnchorElOutsideMax] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElTests, setAnchorElTests] = React.useState<HTMLButtonElement | null>(null)
  const history = useHistory()

  const deviations = useMemo(() => {
    const { requirementMax } = environmentClasses[0]
    return tests.filter(({ wcf }) => wcf > requirementMax)
  }, [environmentClasses, tests])

  const outsideMax = useMemo(() => {
    const { deviationMax } = environmentClasses[0]
    return tests.filter(({ wcf }) => wcf > deviationMax)
  }, [environmentClasses, tests])

  return <Fragment>
    <TableRow>
      <TableCell rowSpan={showDeviations ? 2 : 1}>{environmentClasses.map(e => e.code).join(', ')}</TableCell>
      <TableCell>
        {tests.length}
        <IconButton className="hide-print" onClick={e => setAnchorElTests(e.currentTarget)} style={{ margin: '-14px 0' }}><Icon>info</Icon></IconButton>
        <Popover
          id={maxWbf + 'tests'}
          open={Boolean(anchorElTests)}
          anchorEl={anchorElTests}
          onClose={() => setAnchorElTests(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List dense={true}>
            {tests.map((d, k) => <ListItem key={k} button={true} onClick={() => history.push(`/sample/${d.cubeTest.cubeNumber}`)}>
              <ListItemText primary={`Kubus: ${d.cubeTest.cubeNumber}`} secondary={`WCF: ${format(d.wcf, { maximumFractionDigits: 3, minimumFractionDigits: 3 })}`} />
            </ListItem>)}
          </List>
        </Popover>
      </TableCell>
      <TableCell>
        {deviations.length} <Typography variant="caption">(&gt;{environmentClasses[0].requirementMax})</Typography>
        {deviations.length > 0 && <IconButton className="hide-print" onClick={e => setAnchorElDeviations(e.currentTarget)} style={{ margin: '-14px 0' }}><Icon>info</Icon></IconButton>}
        <Popover
          id={maxWbf + 'deviation'}
          open={Boolean(anchorElDeviations)}
          anchorEl={anchorElDeviations}
          onClose={() => setAnchorElDeviations(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List dense={true}>
            {deviations.map((d, k) => <ListItem key={k} button={true} onClick={() => history.push(`/sample/${d.cubeTest.cubeNumber}`)}>
              <ListItemText primary={`Kubus: ${d.cubeTest.cubeNumber}`} secondary={`WCF: ${format(d.wcf, { maximumFractionDigits: 3, minimumFractionDigits: 3 })}`} />
            </ListItem>)}
          </List>
        </Popover>
      </TableCell>
      <TableCell>{environmentClasses[0].maxDeviationsAllowed}</TableCell>
      <TableCell>
        {outsideMax.length} <Typography variant="caption">(&gt;{environmentClasses[0].deviationMax})</Typography>
        {outsideMax.length > 0 && <IconButton className="hide-print" onClick={e => setAnchorElOutsideMax(e.currentTarget)} style={{ margin: '-14px 0' }}><Icon>info</Icon></IconButton>}
        <Popover
          id={maxWbf + 'ousidemax'}
          open={Boolean(anchorElOutsideMax)}
          anchorEl={anchorElOutsideMax}
          onClose={() => setAnchorElOutsideMax(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List dense={true}>
            {deviations.map((d, k) => <ListItem key={k} button={true} onClick={() => history.push(`/sample/${d.cubeTest.cubeNumber}`)}>
              <ListItemText primary={`Kubus: ${d.cubeTest.cubeNumber}`} secondary={`WCF: ${format(d.wcf, { maximumFractionDigits: 3, minimumFractionDigits: 3 })}`} />
            </ListItem>)}
          </List>
        </Popover>
      </TableCell>
      <TableCell>{outsideMax.length === 0 && (!environmentClasses[0].maxDeviationsAllowed || deviations.length < environmentClasses[0].maxDeviationsAllowed) ? 'Ja' : 'Nee'}</TableCell>
    </TableRow>
    {showDeviations && <TableRow>
      <TableCell colSpan={5} size="small">
        <Typography variant="caption">Afwijkingen: {deviations.map(d => `${d.cubeTest.cubeNumber} (${format(d.wcf, { maximumFractionDigits: 3, minimumFractionDigits: 3 })})`).join(', ')}</Typography>
      </TableCell>
    </TableRow>}
  </Fragment>
}

const ReportWCF: React.FC<{ receipts: ReceiptWithCubeTest[], showDeviations: boolean }> = ({ receipts, showDeviations }) => {
  const filtered = receipts.filter(({ cubeTest }) => cubeTest.trayWeightEmpty && cubeTest.trayWeightWet && cubeTest.trayWeightDry && cubeTest.weight)
  const grouped = useMemo(() => filtered.reduce((groups, receipt) => {
    (receipt.revision.recipe.environmentClasses || []).forEach(environmentClass => {
      const index = groups.findIndex(({ maxWbf }) => maxWbf === environmentClass.maxWbf)
      const wcf = calculateWcf(receipt.cubeTest.trayWeightEmpty || 0, receipt.cubeTest.trayWeightWet || 0, receipt.cubeTest.trayWeightDry || 0, receipt.cubeTest.weight || 0, receipt.revision.recipe.binderTotal, receipt.revision.recipe.absorption)
      if (index >= 0) {
        const hasEnvironmentClass = groups[index].environmentClasses.findIndex(e => e.id === environmentClass.id) >= 0
        if (!hasEnvironmentClass) {
          groups[index].environmentClasses.push(environmentClass)
        }
        const isAlreadyAdded = groups[index].tests.findIndex(t => t.cubeTest.cubeNumber === receipt.cubeTest.cubeNumber) >= 0
        if (!isAlreadyAdded) {
          groups[index].tests.push({ wcf, cubeTest: receipt.cubeTest })
        }
      } else {
        groups.push({ maxWbf: environmentClass.maxWbf, environmentClasses: [environmentClass], tests: [{ wcf, cubeTest: receipt.cubeTest }] })
      }
    })
    return groups
  }, [] as Array<{ environmentClasses: EnvironmentClass[], maxWbf: number, tests: Array<{ wcf: number, cubeTest: CubeTest }> }>).sort((a, b) => b.maxWbf - a.maxWbf), [filtered])

  return <Fragment>
    <Typography variant="h5" style={{ paddingLeft: 16, paddingTop: 32 }}>Water/bindmiddel-factor metingen</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Klasse(n)</TableCell>
          <TableCell>Waarnemingen</TableCell>
          <TableCell>Afwijkingen</TableCell>
          <TableCell>Toegestaan</TableCell>
          <TableCell>Buiten max</TableCell>
          <TableCell>Voldoet</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {grouped.map(group => <GroupRow key={group.maxWbf} group={group} showDeviations={showDeviations} />)}
      </TableBody>
    </Table>
  </Fragment>
}

export default ReportWCF
