import { ApolloClient, ApolloClientOptions, InMemoryCache, HttpLink, IntrospectionFragmentMatcher } from 'apollo-boost'
import { setContext } from 'apollo-link-context'
import { WebSocketLink } from 'apollo-link-ws'
import { split, ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { onError } from 'apollo-link-error'
import introspectionQueryResultData from '../fragmentTypes.json'
import { OperationDefinitionNode } from 'graphql'
import {Settings} from "./settings";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const cache = new InMemoryCache({ fragmentMatcher })

let client: ApolloClient<any>
//const url = new URL(window.location as any)
//const graphqlPath = process.env.GRAPHQL_PATH || '/graphql'
//const uri = `${url.origin}${graphqlPath}`
const uri = Settings.URLs.server+'/graphql';

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) client.cache.writeData({ data: { graphQLErrors: JSON.stringify(graphQLErrors) } })
  if (networkError) client.cache.writeData({ data: { networkError: JSON.stringify(networkError) } })
  client.cache.writeData({ data: { successMutation: false, mutationIsLoading: false } })
})

const responseLogger = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName ? operation.operationName : ""
  const isMutationOperation = (/(update|delete|create)/gi.test(operationName))
  if (isMutationOperation) {
    client.cache.writeData({ data: { successMutation: false, mutationIsLoading: true } })
  }
  return forward(operation).map(result => {
    const ctx = operation.getContext()
    if (ctx.response && ctx.response.ok && isMutationOperation) {
      client.cache.writeData({ data: { successMutation: true, mutationIsLoading: false, graphQLErrors: null, networkError: null } })
    }
    return result
  })
})

export default (options: { LOCAL_STORAGE_KEY: string }) => {
  const { LOCAL_STORAGE_KEY } = options
  const authLink = setContext((_, { headers }) => {
    const authToken = localStorage.getItem(LOCAL_STORAGE_KEY)
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : '',
        'Cache-Control': 'max-age=5'
      }
    }
  })

  const protocol = uri.indexOf('http:')>-1 ? 'ws:' : 'wss:'
//  const wsClient = new SubscriptionClient(`${protocol}//${url.host}${graphqlPath}` as string, {
  const wsClient = new SubscriptionClient(protocol+'//'+Settings.URLs.server,{
    reconnect: true,
    reconnectionAttempts: 50,
    timeout: 20000,
    lazy: true,
    connectionParams: () => {
      const authToken = localStorage.getItem(LOCAL_STORAGE_KEY)
      return {
        Authorization: authToken ? `Bearer ${authToken}` : ''
      }
    }
  })

  const defaultOptions = {
    query: {
      errorPolicy: 'none',
      fetchPolicy: 'network-only'
    },
    mutation: {
      errorPolicy: 'none'
    }
  }

  cache.writeData({
    data: {
      graphQLErrors: null,
      networkError: null,
      successMutation: false,
      mutationIsLoading: false
    }
  })

  const splitWS = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    new WebSocketLink(wsClient),
    new HttpLink({ uri }),
  )

  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const omitTypename = (key: any, value: any) =>
      key === '__typename' ? undefined : value

    const def = getMainDefinition(operation.query)
    if (def && (def as OperationDefinitionNode).operation === "mutation") {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }
    return forward ? forward(operation) : null
  })

  const link = ApolloLink.from([responseLogger, onErrorLink, cleanTypenameLink, authLink, splitWS])

  client = new ApolloClient({
    link,
    cache,
    defaultOptions,
  } as ApolloClientOptions<any>)
  return {
    client,
    wsClient
  }
}
