import gql from 'graphql-tag'
export default gql`query gradingCurvesQuery {
  gradingCurves {
    code
    description
    type
    sieveSteps {
      min
      max
      maxAlt
      sieveSize {
        code
        size
        id
      }
    }
    id
    dMax
  }
}`