import gql from 'graphql-tag'
export default gql`mutation createStrengthClassMutation($strengthClass: BaseStrengthClass!) {
  createStrengthClass(strengthClass: $strengthClass) {
    code
    description
    cilinderPressureStrength
    cubePressureStrength
    bendTensileStrength
    samplePerVolume
    sampleMinimum
    sampleMaximum
    id
  }
}`