import React, { Fragment, useMemo } from 'react'
import { Cube, CubeTest, ResourceType } from '../../types'
import { FamilyWithReceipts } from './EN206'
import * as math from 'mathjs'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import { Link } from 'react-router-dom'

const numberFormatProps = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  displayType: 'text' as 'text'
}

function sortCubeTests(a: CubeTest, b: CubeTest) {
  const aDate = a.sampleDate ? new Date(a.sampleDate) : 0
  const bDate = b.sampleDate ? new Date(b.sampleDate) : 0
  return (aDate > bDate && 1) || (aDate < bDate && -1) || 0
}

const ReportPressureStrength: React.FC<{ family: FamilyWithReceipts }> = ({ family }) => {
  const sorted = useMemo(() => family.receipts.map(receipt => receipt.cubeTest).sort(sortCubeTests), [family.receipts])
  const cubes = useMemo(() => sorted.reduce((arr, cubeTest) => [...arr, ...cubeTest.cubes.filter(cube => cube.numberOfDays && cube.numberOfDays === 28 && cube.pressureStrength && cube.pressureStrength > 0).map(cube => ({ ...cube, cubeNumber: cubeTest.cubeNumber }))], [] as Array<Cube & { cubeNumber: number }>), [sorted])
  const last35 = cubes.slice(cubes.length - 36, cubes.length - 1)
  const stdev35 = last35.length === 35 ? math.std(cubes.map(cube => cube.pressureStrength || 0)) : undefined
  const chunks: Array<Array<Cube & { cubeNumber: number }>> = []
  while (cubes.length > 0) {
    const chunk = cubes.splice(0, 15)
    chunks.push(chunk)
  }
  const maxLen = chunks.length > 0 ? math.max(chunks.map(c => c.length)) : 0
  const { strengthClass, ingredients } = useMemo(() => family.receipts[0].revision.recipe, [family])
  const binders = useMemo(() => ingredients.filter(({ resource }) => resource.type === ResourceType.Cement || resource.type === ResourceType.Filler).map(({ resource }) => resource).sort((a, b) => a.id - b.id), [ingredients])
  const theme = useTheme()

  return (
    <Table style={{ width: 'auto', marginBottom: 32 }} size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={maxLen + 2} style={{ border: 0 }}>
            <Box display="flex">
              <Box>
                <Typography variant="body2">Sterkteklasse</Typography>
                <Typography>{strengthClass?.code}</Typography>
              </Box>
              <Box marginLeft={2}>
                <Typography variant="body2">Bindmiddelen</Typography>
                <Typography>{binders.map(b => b.name).join(', ')}</Typography>
              </Box>
              <Box marginLeft={2}>
                <Typography variant="body2">Familie</Typography>
                <Typography>{family.name}</Typography>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {chunks.map((chunk, i) => {
          const first = chunk[0];
          const last = chunk[chunk.length - 1]
          const values = chunk.map(cube => cube.pressureStrength || 0)
          const mean = math.mean(values)
          const min = math.min(values)
          const max = math.max(values)
          const stdev = math.std(values)
          const fck = stdev35 ? math.mean(values) - 1.48 * stdev35 : undefined
          const stdevmin = stdev35 ? stdev35 * 0.63 : undefined
          const stdevmax = stdev35 ? stdev35 * 1.37 : undefined
          return <Fragment key={i}>
            <TableRow>
              <TableCell style={{ border: 0 }}>{moment(first.testDate).format('D-M-YYYY')}</TableCell>
              {chunk.map(cube => <TableCell key={cube.hash} align="center" style={{ border: 0 }}>
                <Typography variant="caption">{moment(cube.testDate).format('D-M')}</Typography>
                <Typography variant="h6">{cube.pressureStrength}</Typography>
                <Typography variant="body2"><Link to={`/sample/${cube.cubeNumber}`} style={{ color: theme.palette.secondary.light }}>{cube.cubeNumber}</Link></Typography>
              </TableCell>)}
              <TableCell style={{ border: 0 }}>{moment(last.testDate).format('D-M-YYYY')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: 0 }} />
              <TableCell colSpan={maxLen + 1} style={{ border: 0 }}>
                <Box display="flex">
                  <span>min: {min}</span>
                  <span style={{ marginLeft: 16 }}>max: {max}</span>
                  {chunk.length === 15 && <span style={{ marginLeft: 16 }}>x15: <NumberFormat value={Math.round(mean * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.length === 15 && <span style={{ marginLeft: 16 }}>s15: <NumberFormat value={Math.round(stdev * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.length === 15 && stdev35 && <span style={{ marginLeft: 16 }}>s35: <NumberFormat value={Math.round(stdev35 * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.length === 15 && fck && <span style={{ marginLeft: 16 }}>f'ck: <NumberFormat value={Math.round(fck * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.length === 15 && stdevmin && stdevmax && <span style={{ marginLeft: 16 }}><NumberFormat value={Math.round(stdevmin * 10) / 10} {...numberFormatProps} decimalScale={1} /> ... <NumberFormat value={Math.round(stdevmax * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                </Box>
              </TableCell>
            </TableRow>
          </Fragment>
        })}
      </TableBody>
    </Table>)
}

export default ReportPressureStrength
