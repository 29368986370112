import React, { useState, useContext } from 'react'
import { Slide, Theme, Typography, Icon, Dialog, FormControlLabel, Checkbox, DialogContent, Grid, Card, ButtonBase, CardContent, Avatar } from '@material-ui/core'
import { AppContext } from '../App'
import { DialogTitle } from '.'
import { PLANTS } from '../graphql/queries'
import { Plant } from '../types';
import { TransitionProps } from '@material-ui/core/transitions/transition'
import { makeStyles } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { Tasks } from '../App'

const ButtonGrid: React.FC<{ maxWidth?: number | 'none' }> = props => {
  const { children, maxWidth } = props;
  return (
    <Grid container={true} direction="column" justify="center" alignItems="center" style={{ height: '100%' }}>
      <Grid item={true} style={{ maxWidth: maxWidth || 414, marginTop: 16 }}>
        <Grid container={true} justify="center" spacing={2}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}

const useCardButtonStyles = makeStyles((theme: Theme) => ({
  cardButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

const CardButton: React.FC<{ onClick?: () => void }> = props => {
  const { onClick, children } = props;
  const classes = useCardButtonStyles();
  return (
    <Card>
      <ButtonBase onClick={onClick}>
        <CardContent className={classes.cardButton}>
          {children}
        </CardContent>
      </ButtonBase>
    </Card>
  )
}

const SelectPlant: React.FC<{ remember: boolean }> = props => {
  const { loading, data } = useQuery<{ plants: Plant[] }>(PLANTS);
  const { selectPlant } = useContext(AppContext);
  const { remember } = props;
  return (
    <ButtonGrid>
      {!loading && data && data.plants.map((plant, k) => <Grid item={true} key={k}>
        <CardButton onClick={() => selectPlant(plant, remember)}>
          <Typography color="inherit" variant="h6">{plant.name}</Typography>
        </CardButton>
      </Grid>)}
    </ButtonGrid>
  );
}

const tasks = [
  { code: 'sample' as Tasks.SAMPLE, title: 'Monstername uitvoeren', icon: 'assignment' },
  { code: 'pressure' as Tasks.PRESSURE, title: 'Druksterkte meten', icon: 'arrow_downward' },
  { code: 'reports' as Tasks.REPORTS, title: 'Rapportage bekijken', icon: 'trending_up' }
];

const useSelectTaskStyles = makeStyles((theme: Theme) => ({
  buttonAvatar: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main
  }
}));

const SelectTask: React.FC<{ remember: boolean }> = props => {
  const { selectTask } = useContext(AppContext);
  const { remember } = props;
  const classes = useSelectTaskStyles();
  return (
    <ButtonGrid maxWidth={'none'}>
      {tasks.map((task, k) => <Grid item={true} key={k}>
        <CardButton onClick={() => selectTask(task.code, remember)}>
          <Avatar className={classes.buttonAvatar}><Icon>{task.icon}</Icon></Avatar>
          <Typography color="inherit">{task.title}</Typography>
        </CardButton>
      </Grid>)}
    </ButtonGrid>
  );
}

const useDialogStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 'none'
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
})

type SetupDialogProps = {
  open: boolean,
  title: string,
  subTitle?: string,
  step: 'plant' | 'task',
  onClose?: () => void
}

const SetupDialog: React.FC<SetupDialogProps> = (props) => {
  const [state, setState] = useState({ remember: false as boolean | 'plant' | 'task' });
  const { open, title, subTitle, step, onClose } = props;
  const { remember } = state;
  const classes = useDialogStyles();
  return (
    <Dialog open={open} TransitionComponent={Transition} classes={{ paper: classes.dialogPaper }} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
      <DialogTitle subTitle={subTitle} onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        {step === 'plant' && <SelectPlant remember={remember === 'plant'} />}
        {step === 'task' && <SelectTask remember={remember === 'task'} />}
        <FormControlLabel
          style={{ marginTop: 16 }}
          control={
            <Checkbox
              checked={remember === step}
              onChange={(e) => setState({ remember: e.target.checked ? step : false })}
              color="secondary"
            />
          }
          label="Niet opnieuw vragen"
        />
      </DialogContent>
    </Dialog>
  )
}

export default SetupDialog
