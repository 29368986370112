import gql from 'graphql-tag'
export default gql`query userQuery($id: String) {
  user(id: $id) {
    email
    email_verified
    name
    nickname
    username
    picture
    user_id
    last_ip
    logins_count
    last_login
    created_at
    updated_at
  }
}`