import { Box, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import React, { Fragment, useMemo } from 'react'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import moment from 'moment'
import { format } from 'react-numberinput-formatter'
import { calculateWcf } from '../Sampling/CubeTestWcfStep'
import { Addition, Cement, ConsistencyTestType, CubeType, Excipient, Extra, Filler, ResourceType } from '../../types'

const useStyles = makeStyles(theme => ({
  table: {
    width: 'auto',
    '& td': {
      border: 0
    },
    '& tr:first-child td': {
      borderTop: '1px solid',
      borderTopColor: theme.palette.divider
    }
  }
}))

type RowGroup = Array<{ title: string, getValue: (receipt: ReceiptWithCubeTest) => string | number | JSX.Element | undefined | null }>

const recipeParamsGroup: RowGroup = [
  { title: 'Sterkteklasse', getValue: ({ revision }) => revision.recipe.strengthClass?.code },
  { title: 'Mileuklasse', getValue: ({ revision }) => (revision.recipe.environmentClasses || []).map(e => e.code).join(', ') },
  { title: 'Consistentieklasse', getValue: ({ revision }) => (revision.recipe.consistencyClass.code) }
]
const amountGroup: RowGroup = [{ title: 'Productie (m³)', getValue: ({ amount }) => format(amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }]
const sandGroup: RowGroup = [{
  title: 'Dmax 4 (%)', getValue: ({ revision }) => {
    const additionTotal = revision.recipe.ingredients.reduce((total, ingredient) => ingredient.resource.type === ResourceType.Addition ? total += ingredient.amount : total, 0)
    const sandTotal = revision.recipe.ingredients.reduce((total, ingredient) => ingredient.resource.type === ResourceType.Addition && (ingredient.resource as Addition).isSand ? total += ingredient.amount : total, 0)
    return format(100 / additionTotal * sandTotal, { maximumFractionDigits: 1, minimumFractionDigits: 1 })
  }
}]
const receiptGroup: RowGroup = [
  { title: 'Receptnummer', getValue: ({ revision }) => revision.recipe.id },
  { title: 'Ontwerp WCF/WBF', getValue: ({ revision }) => format(revision.recipe.wbf, { maximumFractionDigits: 3, minimumFractionDigits: 3 }) },
  { title: 'Hoeveelheid fijn (L)', getValue: ({ revision }) => format(revision.recipe.percentageFine, { maximumFractionDigits: 2 }) },
  { title: 'Totaal bindmiddel (kg)', getValue: ({ revision }) => format(revision.recipe.binderTotal, { maximumFractionDigits: 2 }) },
  { title: 'Bonnummer', getValue: ({ id }) => id },
  { title: 'Aannemer', getValue: ({ customer }) => customer }
]
const cubeTestGroup: RowGroup = [
  { title: 'Specie temperatuur (°C)', getValue: ({ cubeTest }) => `${format(cubeTest.temperature || 0, { maximumFractionDigits: 2 })}` },
  { title: 'Gemeten WCF/WBF', getValue: ({ cubeTest, revision }) => format(calculateWcf(cubeTest.trayWeightEmpty || 0, cubeTest.trayWeightWet || 0, cubeTest.trayWeightDry || 0, cubeTest.weight || 0, revision.recipe.binderTotal, revision.recipe.absorption), { maximumFractionDigits: 3, minimumFractionDigits: 3 }) },
  { title: 'Vol. massa sp (kg/m³)', getValue: ({ cubeTest }) => format((cubeTest.weight || 0) / 3.375, { maximumFractionDigits: 2 }) },
  { title: 'Luchtgehalte (%)', getValue: ({ cubeTest }) => format(cubeTest.airPercentage || 0, { maximumFractionDigits: 2 }) }
]
const cubeNumberGroup: RowGroup = [{ title: 'Kubusnummer', getValue: ({ cubeTest }) => cubeTest.cubeNumber }]
const pressureStrengthGroup: RowGroup = [
  { title: 'Vol. massa beton (kg/m³)', getValue: ({ revision }) => format(revision.recipe.density, { maximumFractionDigits: 2 }) },
  { title: 'Druksterkte 28d (N/mm²)', getValue: ({ cubeTest }) => cubeTest.cubes.filter(c => c.type === CubeType.Pressure && c.numberOfDays === 28).map(c => c.pressureStrength ? format(c.pressureStrength, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) : null).join(' | ') },
  { title: 'Druksterkte 7d (N/mm²)', getValue: ({ cubeTest }) => cubeTest.cubes.filter(c => c.type === CubeType.Pressure && c.numberOfDays === 7).map(c => c.pressureStrength ? format(c.pressureStrength, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) : null).join(' | ') }
]

const DayTable: React.FC<{ date: Date, receipts: ReceiptWithCubeTest[] }> = ({ date, receipts }) => {
  const { table: tableStyles } = useStyles()

  const consistencyTests = useMemo(() => receipts.reduce((tests, { cubeTest }) => {
    cubeTest.consistencyTests.forEach(({ consistencyTestType }) => {
      if (tests.findIndex(t => t.id === consistencyTestType.id) < 0) {
        tests.push(consistencyTestType)
      }
    })
    return tests
  }, [] as ConsistencyTestType[]), [receipts])

  const consistencyTestGroup = useMemo(() => consistencyTests.map(test => ({
    title: `${test.description}${test.parameters.length > 0 && test.parameters[0].unit ? ` (${test.parameters.map(p => p.unit).join(', ')})` : ''}`,
    getValue: ({ cubeTest }: ReceiptWithCubeTest) => {
      const index = cubeTest.consistencyTests.findIndex(c => c.consistencyTestType.id === test.id)
      return index >= 0 ? cubeTest.consistencyTests[index].values.map(v => v.value).join(', ') : ''
    }
  })), [consistencyTests])

  const gravels = useMemo(() => receipts.reduce((gravel, { revision }) => {
    revision.recipe.ingredients.filter(ingredient => ingredient.resource.type === ResourceType.Addition && !(ingredient.resource as Addition).isSand).forEach(({ resource }) => {
      if (gravel.findIndex(g => g.id === resource.id) < 0) {
        gravel.push(resource as Addition)
      }
    })
    return gravel
  }, [] as Addition[]), [receipts])

  const gravelGroup = useMemo(() => gravels.map(gravel => ({
    title: `${gravel.name} (%)`,
    getValue: ({ revision }: ReceiptWithCubeTest) => {
      const ingredient = revision.recipe.ingredients.find(i => i.resource.id === gravel.id)
      if (ingredient) {
        const additionTotal = revision.recipe.ingredients.reduce((total, ingredient) => ingredient.resource.type === ResourceType.Addition ? total += ingredient.amount : total, 0)
        return format(100 / additionTotal * ingredient.amount, { maximumFractionDigits: 1, minimumFractionDigits: 1 })
      }
      return ''
    }
  })), [gravels])

  const binders = useMemo(() => receipts.reduce((binders, { revision }) => revision.recipe.ingredients.filter(ingredient => ingredient.resource.type === ResourceType.Cement || ingredient.resource.type === ResourceType.Filler).map(({ resource }) => resource as Cement | Filler), [] as Array<Cement | Filler>), [receipts])

  const binderGroup = useMemo(() => binders.map(binder => ({
    title: `${binder.name} (kg)`,
    getValue: ({ revision }: ReceiptWithCubeTest) => {
      const ingredient = revision.recipe.ingredients.find(i => i.resource.id === binder.id)
      return ingredient ? format(ingredient.amount, { maximumFractionDigits: 2 }) : ''
    }
  })), [binders])

  const extras = useMemo(() => receipts.reduce((extras, { revision }) => revision.recipe.ingredients.filter(ingredient => ingredient.resource.type === ResourceType.Extra || ingredient.resource.type === ResourceType.Excipient).map(({ resource }) => resource as Excipient | Extra), [] as Array<Excipient | Extra>), [receipts])

  const extrasGroup = useMemo(() => extras.map(extra => ({
    title: `${extra.name} (kg)`,
    getValue: ({ revision }: ReceiptWithCubeTest) => {
      const ingredient = revision.recipe.ingredients.find(i => i.resource.id === extra.id)
      return ingredient ? format(ingredient.amount, { maximumFractionDigits: 2 }) : ''
    }
  })), [extras])

  const groups = useMemo(() => {
    return [
      recipeParamsGroup,
      amountGroup,
      sandGroup,
      gravelGroup,
      binderGroup,
      extrasGroup,
      receiptGroup,
      [...consistencyTestGroup, ...cubeTestGroup],
      cubeNumberGroup,
      pressureStrengthGroup
    ]
  }, [consistencyTestGroup, gravelGroup, binderGroup, extrasGroup])

  return <Box className="page">
    <Box className="report-title" paddingLeft={2} paddingTop={2} marginBottom={1}>
      <Typography variant="h5">Kubusoverzicht: monsername datum {moment(date).format('YYYY-MM-DD')}</Typography>
    </Box>
    <Table size="small" className={tableStyles}>
      {groups.map((rows, l) => rows.length > 0 ? <TableBody key={l}>
        {rows.map(({ title, getValue }, m) => <TableRow key={m}>
          <TableCell>{title}</TableCell>
          {receipts.map((receipt, n) => <TableCell key={n}>{getValue(receipt)}</TableCell>)}
        </TableRow>)}
      </TableBody> : null)}
    </Table>
  </Box>
}

const Day: React.FC<{ receipts: ReceiptWithCubeTest[] }> = ({ receipts }) => {
  const samplesPerDay = useMemo(() => {
    return receipts.reduce((days, receipt) => {
      const index = days.findIndex(day => moment(day.date).isSame(receipt.cubeTest.sampleDate, 'day'))
      if (index >= 0) {
        days[index].receipts.push(receipt)
      } else {
        days.push({ date: moment(receipt.cubeTest?.sampleDate).toDate(), receipts: [receipt] })
      }
      return days
    }, [] as Array<{ date: Date, receipts: ReceiptWithCubeTest[] }>).sort((a, b) => a.date.getTime() - b.date.getTime())
  }, [receipts])

  return <Fragment>
    {samplesPerDay.map(({ date, receipts }, k) => <DayTable key={k} date={date} receipts={receipts} />)}
  </Fragment>
}

export default Day
