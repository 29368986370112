import gql from 'graphql-tag'
export default gql`query sieveSetsQuery {
  sieveSets {
    code
    description
    type
    sieveSteps {
      sieveSize {
        code
        size
        id
      }
    }
    id
  }
}`