import gql from 'graphql-tag'
export default gql`mutation createConsistencyTestTypeMutation($consistencyTestType: BaseConsistencyTestType!) {
  createConsistencyTestType(consistencyTestType: $consistencyTestType) {
    code
    description
    parameters {
      unit
      label
      placeholder
    }
    id
  }
}`