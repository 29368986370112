import gql from 'graphql-tag'
export default gql`mutation updateConsistencyClassMutation($consistencyClassId: Int!, $consistencyClass: BaseConsistencyClass!) {
  updateConsistencyClass(consistencyClassId: $consistencyClassId, consistencyClass: $consistencyClass) {
    code
    description
    minRequirement
    maxRequirement
    minDeviation
    maxDeviation
    maxDeviationsAllowed
    consistencyTestTypes {
      code
      description
      parameters {
        unit
        label
        placeholder
      }
      id
    }
    id
  }
}`