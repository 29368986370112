import gql from 'graphql-tag'
export default gql`mutation updateGradingCurveMutation($gradingCurveId: Int!, $gradingCurve: BaseGradingCurve!) {
  updateGradingCurve(gradingCurveId: $gradingCurveId, gradingCurve: $gradingCurve) {
    code
    description
    type
    sieveSteps {
      min
      max
      maxAlt
      sieveSize {
        code
        size
        id
      }
    }
    id
    dMax
  }
}`