import gql from 'graphql-tag'

const PARTIAL_RECEIPTS = gql`query receiptsQuery($endDate: DateTime, $startDate: DateTime, $dateType: DateType, $plantId: Int!) {
  receipts(endDate: $endDate, startDate: $startDate, dateType: $dateType, plantId: $plantId) {
    date
    customer
    remarks
    truck
    amount
    charges {
      amount
      resources {
        amount
      }
    }
    plant {
      name
      id
    }
    id
    revision {
      id
      date
      author
      recipe {
        recipeName
        ingredients {
          amount
          resource {
            ...on Addition {
              id
              name
              type
              isSand
            }
            ... on Cement {
              id
              name
              type
            }
            ... on Excipient {
              id
              name
              type
            }
            ... on Extra {
              id
              name
              type
            }
            ... on Filler {
              id
              name
              type
            }
            ... on Water {
              id
              name
              type
            }
          }
        }
        consistencyClass {
          code
          description
          minRequirement
          maxRequirement
          minDeviation
          maxDeviation
          maxDeviationsAllowed
          consistencyTestTypes {
            code
            description
            parameters {
              unit
              label
              placeholder
            }
            id
          }
          id
        }
        environmentClasses {
          code
          description
          minCement
          maxWbf
          requirementMax
          deviationMax
          maxDeviationsAllowed
          minimumAirPercentages {
            largestGrain
            airPercentage
          }
          encroachment
          id
        }
        strengthClass {
          code
          description
          cilinderPressureStrength
          cubePressureStrength
          bendTensileStrength
          samplePerVolume
          sampleMinimum
          sampleMaximum
          id
        }
        airPercentage
        wbf
        allowRecycleWater
        remarksInternal
        remarksExternal
        codeExternal
        revision
        status
        publishedBy
        publishedDate
        archivedBy
        archivedDate
        families {
          name
          id
        }
        id
        binderTotal
        percentageFine
        density
        absorption
      }
    }
    cubeTest {
      sampleDate
      weight
      temperature
      airPercentage
      trayWeightEmpty
      trayWeightWet
      trayWeightDry
      remarks
      cubes {
        hash
        type
        weight
        pressureStrength
        penetration
        temperature
        testDate
        numberOfDays
        remarks
        cancelRemarks
        status
        length
        height
        width
      }
      consistencyTests {
        consistencyTestType {
          code
          description
          parameters {
            unit
            label
            placeholder
          }
          id
        }
        values {
          value
          parameter {
            unit
            label
            placeholder
          }
        }
      }
      cubeNumber
    }
  }
}`

export default PARTIAL_RECEIPTS
