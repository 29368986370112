import React, { Fragment, useState, useContext, useMemo, useCallback } from 'react'
import { Typography, AppBar, Toolbar, IconButton, Icon, Menu, MenuItem, ListItemIcon, ButtonBase, MenuList, Divider, ListItemText, ListItem, List, Grid, Box, Popover, makeStyles, Theme, Chip, Fade, ListSubheader } from '@material-ui/core'
import { AppContext } from '../App'
import { WheelbarrowIcon, PressureIcon } from '../icons'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useHistory, useLocation } from 'react-router-dom'
import {Settings} from "../config/settings";

const GET_CLIENT_ERRORS = gql`
query GetClientErrors {
  graphQLErrors @client
  networkError @client
}
`
const apps = [
  { href: Settings.URLs.recipeApp || (window.location.origin + '/receptuur'), title: 'Receptuur', icon: require('../images/receptuur-icon.png'), disabled: false },
  { href: Settings.URLs.orderApp || (window.location.origin + '/order'), title: 'Orders', icon: <Icon fontSize="large">assignment</Icon>, disabled: false }
]

const navItems = [
  {
    subItems: [
      { title: 'Monstername', icon: <WheelbarrowIcon />, link: '/sample', task: 'sample' },
      { title: 'Druksterkte', icon: <PressureIcon />, link: '/pressure', task: 'pressure' }
    ]
  },
  {
    subHeader: 'Rapportage',
    subItems: [
      { title: 'Meetresultaten EN206', icon: <Icon>timeline</Icon>, link: '/reports/en206', task: 'reports' },
      { title: 'Meetresultaten', icon: <Icon>view_module</Icon>, link: '/reports/all', task: 'reports' },
      { title: 'Kubusmaandlijst', icon: <Icon>event</Icon>, link: '/reports/month', task: 'reports' },
      { title: 'Daglijst', icon: <Icon>view_list</Icon>, link: '/reports/day', task: 'reports' }
    ]
  }
]

const useStyles = makeStyles((theme: Theme) => ({
  saved: {
    background: theme.palette.success.main
  },
  saving: {
    background: theme.palette.info.main
  },
  root: {
    background: 'none'
  },
  error: {
    background: theme.palette.error.main
  }
}))

type AppToolbarProps = {
  user: { name: string },
  plant?: { name: string }
}

const AppToolbar: React.FC<AppToolbarProps> = ({ user, plant }) => {
  const { selectPlant, logout } = useContext(AppContext)
  const [navAnchorEl, setNavAnchorEl] = useState(null as null | HTMLElement)
  const [plantAnchorEl, setPlantAnchorEl] = useState(null as null | HTMLElement)
  const [appsAnchorEl, setAppsAnchorEl] = useState(null as null | HTMLElement)
  const errors = useQuery(GET_CLIENT_ERRORS)
  const { isSaving, isSaved } = useContext(AppContext)
  const history = useHistory()
  const location = useLocation()
  const active: { title: string, task: string, icon: JSX.Element } | undefined = useMemo(() => navItems.reduce((items, item) => items.concat(item.subItems), [] as any).find((item: { link: string }) => location.pathname.indexOf(item.link) >= 0), [location.pathname])


  const handleNavClick = useCallback((link) => {
    history.push(link)
    setNavAnchorEl(null)
  }, [history])

  const hasError: boolean = useMemo(() => {
    if (errors.data.networkError) {
      return true
    }
    return false
  }, [errors])
  const { saved: savedClass, root: rootClass, error: errorClass, saving: savingClass } = useStyles()

  return (
    <AppBar className={hasError ? errorClass : undefined}>
      <Toolbar>
        {active && <Fragment>
          <ButtonBase onClick={(e) => setNavAnchorEl(e.currentTarget)}>
            {active.icon}&nbsp;&nbsp;
              <Typography variant="h6">{active.title}</Typography>&nbsp;<Icon>arrow_drop_down</Icon>
          </ButtonBase>
          <Menu disableAutoFocusItem={true} anchorEl={navAnchorEl} open={Boolean(navAnchorEl)} onClose={() => setNavAnchorEl(null)} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
            {navItems.map((sub, n) => <MenuList key={n} {...sub.subHeader && { subheader: <ListSubheader>{sub.subHeader}</ListSubheader> }}>
              {sub.subItems.map((item, k) => <MenuItem key={k} selected={item.link === location.pathname} onClick={() => handleNavClick(item.link)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                {item.title}
              </MenuItem>)}
            </MenuList>)}
          </Menu>
        </Fragment>}

        <span style={{ flex: 1 }} />
        <Fade in={isSaved || hasError || isSaving} timeout={300}><Chip
          className={isSaved ? savedClass : isSaving ? savingClass : rootClass}
          label={isSaved ? 'Gegevens opgeslagen' : isSaving ? 'Wijzigingen Opslaan' : hasError ? 'Er is een fout opgetreden' : ''}
        /></Fade>

        <IconButton onClick={e => setAppsAnchorEl(e.currentTarget)}>
          <Icon>apps</Icon>
        </IconButton>
        <Popover onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')} anchorEl={appsAnchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={Boolean(appsAnchorEl)} onClose={() => setAppsAnchorEl(null)}>
          <Grid container={true} spacing={3} style={{ margin: 16, maxWidth: 200, width: 250 }} justify="center" wrap="wrap">
            {apps.map(({ title, href, icon, disabled }, k) => (
              <Grid item={true} key={k}>
                <ButtonBase onClick={() => (window.location.href = href)} disabled={disabled} {...disabled && { style: { opacity: 0.5 } }}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {typeof icon === 'string' ? <img src={icon} alt={title} width={36} /> : icon}
                    <Typography variant="subtitle2">{title}</Typography>
                  </Box>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Popover>
        {plant && <Fragment>
          <List disablePadding={true} dense={true} id={"profile_menu"}>
            <ListItem button={true} onClick={(e) => setPlantAnchorEl(e.currentTarget)}>
              <ListItemText
                primary={user.name}
                secondary={plant.name}
              />
              <Icon>arrow_drop_down</Icon>
            </ListItem>
          </List>
          <Menu anchorEl={plantAnchorEl} open={Boolean(plantAnchorEl)} disableAutoFocusItem={true} onClose={() => setPlantAnchorEl(null)} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
            <MenuItem onClick={() => setPlantAnchorEl(null)}>
              <ListItemIcon><Icon>account_circle</Icon></ListItemIcon>
              {user.name}
            </MenuItem>
            <MenuItem onClick={() => { selectPlant(undefined, true); setPlantAnchorEl(null) }}>
              <ListItemIcon><Icon>compare_arrows</Icon></ListItemIcon>
              Centrale wijzigen
              </MenuItem>
            <Divider />
            <MenuItem onClick={() => { logout(); setPlantAnchorEl(null) }}>
              <ListItemIcon><Icon>power_settings_new</Icon></ListItemIcon>
              Uitloggen
              </MenuItem>
          </Menu>
        </Fragment>}
      </Toolbar>
    </AppBar>
  );
}

export default AppToolbar;
