import React, { useState, useCallback, useRef, Fragment, useMemo, useEffect } from 'react'
import { Card, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Icon, Button, CardContent, MenuItem, Select, TextField, Popover, Typography } from '@material-ui/core'
import { Cube, CubeStatus, CubeType } from '../../types'
import { ConfirmDialog } from '..'
import moment from 'moment'
import useAuthorized from '../../useAuthorized'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import { v4 as uuid } from 'uuid'

type CubeTestNumberOfDaysCellProps = {
  cube: Cube,
  sampleDate: Date,
  onChange: (cube: Cube) => void
}

const CubeTestNumberOfDaysCell: React.FC<CubeTestNumberOfDaysCellProps> = ({ cube, sampleDate, onChange }) => {
  const [editAnchorEl, setEditMode] = useState(null as HTMLElement | null)
  const [numberOfDays, setNumberOfDays] = useState(cube.numberOfDays)
  const inputRef = useRef(null)
  const canEdit = useAuthorized(['update:cubetests'])

  useEffect(() => {
    setNumberOfDays(cube.numberOfDays)
  }, [cube.numberOfDays])

  const handleNumberOfDaysChange = useCallback(() => {
    setEditMode(null)
    onChange({ ...cube, numberOfDays, testDate: moment(sampleDate).add(numberOfDays, 'days').toDate() })
  }, [cube, sampleDate, numberOfDays, onChange])

  return (
    <TableCell size="small" onClick={(e: any) => canEdit && !Boolean(editAnchorEl) && setEditMode(e.currentTarget)} style={{ cursor: 'pointer' }}>
      <Popover
        open={Boolean(editAnchorEl)}
        PaperProps={{ square: true }}
        anchorEl={editAnchorEl}
        transformOrigin={{ vertical: 0, horizontal: -24 }}
        onClose={() => handleNumberOfDaysChange()}
        onEntered={(ref: HTMLElement) => {
          if (inputRef.current) {
            ref.removeAttribute('tabindex');
            (inputRef.current as unknown as HTMLInputElement).focus()
          }
        }}
      >
        <div style={{ padding: '8px 24px', display: 'flex', flexDirection: 'column' }}>
          <TextField
            inputRef={inputRef}
            margin="normal"
            variant="outlined"
            placeholder="Aantal dagen"
            value={numberOfDays || ''}
            inputProps={{
              pattern: '\\d*'
            }}
            disabled={!canEdit}
            onChange={e => setNumberOfDays(Number(e.target.value))}
          />
        </div>
      </Popover>
      {numberOfDays ? <Fragment>{numberOfDays}&nbsp;&nbsp;&nbsp;<Icon fontSize="small" style={{ marginBottom: -5 }}>create</Icon></Fragment> : <Typography variant="subtitle2" style={{ opacity: 0.7 }}>Aantal dagen opgeven</Typography>}
    </TableCell>
  )
}

type CubeRowProps = {
  cube: Cube
  sampleDate: Date
  disableDelete?: boolean
  onDelete: (cube: Cube) => void
  onChange: (cube: Cube) => void
}

const CubeRow: React.FC<CubeRowProps> = props => {
  const { cube, sampleDate, onDelete, disableDelete, onChange } = props
  const canEdit = useAuthorized(['update:cubetests'])

  const handleCubeTypeChange = useCallback((cubeType: CubeType) => {
    cube.type = cubeType
    onChange(cube)
  }, [cube, onChange])

  return (
    <TableRow>
      <TableCell size="small">
        <Select
          value={cube.type}
          onChange={(e) => handleCubeTypeChange(e.target.value as CubeType)}
          disableUnderline={true}
          disabled={!canEdit}
        >
          <MenuItem value={CubeType.Pressure}>Druksterkte</MenuItem>
          <MenuItem value={CubeType.Penetration}>Indringing</MenuItem>
          <MenuItem value={CubeType.Ripeness}>Rijpheid</MenuItem>
        </Select>
      </TableCell>
      <CubeTestNumberOfDaysCell
        cube={cube}
        sampleDate={sampleDate}
        onChange={onChange}
      />
      <TableCell size="small">{moment(cube.testDate).format('D MMMM YYYY')}</TableCell>
      <TableCell size="small" style={{ width: 1 }}>
        {!disableDelete && canEdit && <IconButton onClick={() => onDelete(cube)}>
          <Icon>delete</Icon>
        </IconButton>}
      </TableCell>
    </TableRow>
  )
}

const CubeTestCubesStep: React.FC<{ receipt: ReceiptWithCubeTest, onChange: (receipt: ReceiptWithCubeTest) => void }> = ({ receipt, onChange }) => {
  const [confirmProps, confirmDelete] = useState({ open: false } as { open: boolean, onCancel?: () => void, onConfirm?: () => void })
  const cubes28days = useMemo(() => receipt.cubeTest.cubes.filter(cube => cube.numberOfDays === 28) || [], [receipt.cubeTest.cubes])
  const sorted = useMemo(() => receipt.cubeTest.cubes.sort((a, b) => new Date(a.testDate).getTime() - new Date(b.testDate).getTime()), [receipt.cubeTest.cubes])
  const canEdit = useAuthorized(['update:cubetests'])

  const handleChange = useCallback(async (cube: Cube) => {
    const cubes = receipt.cubeTest.cubes || []
    const index = cubes.findIndex(c => c.hash === cube.hash)
    index >= 0 && (cubes[index] = cube)
    onChange({ ...receipt, cubeTest: { ...receipt.cubeTest, cubes: [...cubes] } })
  }, [receipt, onChange])

  const handleAdd = useCallback(async () => {
    const cube: Cube = {
      hash: uuid(),
      testDate: new Date(),
      numberOfDays: 0,
      status: CubeStatus.Active,
      type: CubeType.Pressure,
      weight: 0,
    }
    onChange({ ...receipt, cubeTest: { ...receipt.cubeTest, cubes: [...receipt.cubeTest.cubes, cube] } })
  }, [receipt, onChange])

  const handleAddRipeness = useCallback(async () => {
    const cube: Cube = {
      hash: uuid(),
      testDate: new Date(),
      numberOfDays: 0,
      status: CubeStatus.Active,
      type: CubeType.Ripeness,
      weight: 0,
    };
    [1, 3, 7, 14].forEach(numberOfDays => {
      const data = { ...cube, testDate: moment(receipt.cubeTest.sampleDate).add(numberOfDays, 'days').toDate(), numberOfDays }
      receipt.cubeTest.cubes.push(data)
    })
    onChange({ ...receipt, cubeTest: { ...receipt.cubeTest, cubes: [...receipt.cubeTest.cubes] } })
  }, [receipt, onChange])

  const handleDelete = useCallback((cube: Cube) => {
    confirmDelete({
      open: true,
      onCancel: () => confirmDelete({ open: false }),
      onConfirm: async () => {
        const index = receipt.cubeTest.cubes.findIndex(c => c === cube)
        index >= 0 && receipt.cubeTest.cubes.splice(index, 1)
        onChange({ ...receipt, cubeTest: { ...receipt.cubeTest, cubes: [...receipt.cubeTest.cubes] } })
        confirmDelete({ open: false })
      }
    })
  }, [confirmDelete, onChange, receipt])

  return (
    <div>
      <Card>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type meting</TableCell>
              <TableCell>Aantal dagen</TableCell>
              <TableCell colSpan={2}>Test datum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map((cube, k) => {
              return (
                <CubeRow key={k}
                  cube={cube}
                  sampleDate={receipt.cubeTest.sampleDate}
                  onDelete={handleDelete}
                  onChange={handleChange}
                  disableDelete={cubes28days.length === 1 && cube.numberOfDays === 28}
                />
              )
            })}
          </TableBody>
        </Table>
        {canEdit && <CardContent>
          <div style={{ display: 'flex' }}>
            <Button color="primary" variant="contained" onClick={handleAdd} style={{ marginRight: 8 }}>Kubus toevoegen</Button>
            <Button color="primary" variant="contained" onClick={handleAddRipeness}>Gewogen rijpheid kubussen toevoegen</Button>
          </div>
        </CardContent>}
      </Card>
      <ConfirmDialog
        title="Kubus verwijderen"
        content="Weet u zeker dat u deze kubus wilt verwijderen?"
        confirmText="Verwijderen"
        {...confirmProps}
      />
    </div>
  )
}

export default CubeTestCubesStep
