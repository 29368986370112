import React, { createContext, useContext } from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const WsClientContext = createContext({} as { wsClient: SubscriptionClient });

export const WsClientProvider: React.FC<{ wsClient: SubscriptionClient }> = ({ wsClient, children }) =>
  <WsClientContext.Provider value={{ wsClient }}>{children}</WsClientContext.Provider>

export const useWsClient = () => {
  const { wsClient } = useContext(WsClientContext);
  return wsClient;
}
