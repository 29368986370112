import gql from 'graphql-tag'
export default gql`query strengthClassesQuery {
  strengthClasses {
    code
    description
    cilinderPressureStrength
    cubePressureStrength
    bendTensileStrength
    samplePerVolume
    sampleMinimum
    sampleMaximum
    id
  }
}`