import gql from 'graphql-tag'
export default gql`mutation updateConsistencyTestTypeMutation($consistencyTestTypeId: Int!, $consistencyTestType: BaseConsistencyTestType!) {
  updateConsistencyTestType(consistencyTestTypeId: $consistencyTestTypeId, consistencyTestType: $consistencyTestType) {
    code
    description
    parameters {
      unit
      label
      placeholder
    }
    id
  }
}`