import gql from 'graphql-tag'
export default gql`query receiptQuery($receiptId: Int!) {
  receipt(receiptId: $receiptId) {
    date
    customer
    remarks
    truck
    amount
    charges {
      amount
      resources {
        amount
      }
    }
    plant {
      name
      id
    }
    id
    revision {
      id
      date
      author
      recipe {
        recipeName
        chlorideClass {
          code
          description
          maxChloride
          id
        }
        consistencyClass {
          code
          description
          minRequirement
          maxRequirement
          minDeviation
          maxDeviation
          maxDeviationsAllowed
          consistencyTestTypes {
            code
            description
            parameters {
              unit
              label
              placeholder
            }
            id
          }
          id
        }
        environmentClasses {
          code
          description
          minCement
          maxWbf
          requirementMax
          deviationMax
          maxDeviationsAllowed
          minimumAirPercentages {
            largestGrain
            airPercentage
          }
          encroachment
          id
        }
        strengthClass {
          code
          description
          cilinderPressureStrength
          cubePressureStrength
          bendTensileStrength
          samplePerVolume
          sampleMinimum
          sampleMaximum
          id
        }
        typeOfWork {
          code
          description
          maxChloride
          minPercentageFine
          id
        }
        gradingCurve {
          code
          description
          type
          sieveSteps {
            min
            max
            maxAlt
            sieveSize {
              code
              size
              id
            }
          }
          id
          dMax
        }
        attest {
          attestNumber
          expirationDate
          id
        }
        airPercentage
        wbf
        allowRecycleWater
        remarksInternal
        remarksExternal
        codeExternal
        revision
        status
        publishedBy
        publishedDate
        archivedBy
        archivedDate
        families {
          name
          id
        }
        id
        ingredients {
          amount
          percentage
          moisture
          absorption
          kFactor
          attestPercentage
          resource {
            ... on Addition {
              name
              type
              articleCode
              brand
              supplier
              price
              density
              chloridePercentage
              alkaliPercentage
              plants {
                name
                id
              }
              id
              absorption
              moisture
              isSand
              sieveTest {
                startingWeight
                sieveSet {
                  code
                  description
                  type
                  sieveSteps {
                    sieveSize {
                      code
                      size
                      id
                    }
                  }
                  id
                }
                sieveSteps {
                  sieveSize {
                    code
                    size
                    id
                  }
                  restWeight
                }
                finenessModulus
              }
              gradingCurve {
                code
                description
                type
                sieveSteps {
                  min
                  max
                  maxAlt
                  sieveSize {
                    code
                    size
                    id
                  }
                }
                id
                dMax
              }
            }
            ... on Cement {
              name
              type
              articleCode
              brand
              supplier
              price
              density
              chloridePercentage
              alkaliPercentage
              plants {
                name
                id
              }
              id
              strengthWeek
              strengthNorm
            }
            ... on Excipient {
              name
              type
              articleCode
              brand
              supplier
              price
              density
              chloridePercentage
              alkaliPercentage
              plants {
                name
                id
              }
              id
              dosingMethod
              mainEffect
              secondaryEffect
              absorption
              moisture
            }
            ... on Extra {
              name
              type
              articleCode
              brand
              supplier
              price
              density
              chloridePercentage
              alkaliPercentage
              plants {
                name
                id
              }
              id
              percentageFine
              absorption
              moisture
            }
            ... on Filler {
              name
              type
              articleCode
              brand
              supplier
              price
              density
              chloridePercentage
              alkaliPercentage
              plants {
                name
                id
              }
              id
              cementKFactors {
                kFactor
                cement {
                  name
                  type
                  articleCode
                  brand
                  supplier
                  price
                  density
                  chloridePercentage
                  alkaliPercentage
                  plants {
                    name
                    id
                  }
                  id
                  strengthWeek
                  strengthNorm
                }
              }
            }
            ... on Water {
              name
              type
              articleCode
              brand
              supplier
              price
              density
              chloridePercentage
              alkaliPercentage
              plants {
                name
                id
              }
              id
              siltPercentage
            }
          }
        }
        binderTotal
        percentageFine
        density
        absorption
      }
    }
    cubeTest {
      sampleDate
      weight
      temperature
      airPercentage
      trayWeightEmpty
      trayWeightWet
      trayWeightDry
      remarks
      cubes {
        hash
        type
        weight
        pressureStrength
        penetration
        temperature
        testDate
        numberOfDays
        remarks
        cancelRemarks
        status
        length
        height
        width
      }
      consistencyTests {
        consistencyTestType {
          code
          description
          parameters {
            unit
            label
            placeholder
          }
          id
        }
        values {
          value
          parameter {
            unit
            label
            placeholder
          }
        }
      }
      cubeNumber
    }
  }
}`