import gql from 'graphql-tag'
export default gql`mutation updateEnvironmentClassMutation($environmentClassId: Int!, $environmentClass: BaseEnvironmentClass!) {
  updateEnvironmentClass(environmentClassId: $environmentClassId, environmentClass: $environmentClass) {
    code
    description
    minCement
    maxWbf
    requirementMax
    deviationMax
    maxDeviationsAllowed
    minimumAirPercentages {
      largestGrain
      airPercentage
    }
    encroachment
    id
  }
}`