import gql from 'graphql-tag'
export default gql`mutation createSieveSetMutation($sieveSet: BaseSieveSet!) {
  createSieveSet(sieveSet: $sieveSet) {
    code
    description
    type
    sieveSteps {
      sieveSize {
        code
        size
        id
      }
    }
    id
  }
}`