import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Cube, CubeStatus, CubeTest, CubeType, DateType, Receipt } from '../types'
import { AppContext } from '../App'
import { Grid, LinearProgress, List, ListSubheader, makeStyles } from '@material-ui/core'
import { ReceiptWithCubeTest } from './Sampling'
import moment from 'moment'
import EmptyState from '../components/EmptyState'
import { PressureIcon } from '../icons'
import { Loading } from '../components'
import CubeListItem from '../components/PressureStrength/CubeListItem'
import CubesScheduled from '../components/PressureStrength/CubesScheduled'
import CubeEdit from '../components/PressureStrength/CubeEdit'
import CancelCubeDialog from '../components/PressureStrength/CancelCubeDialog'
import { sanitizeReceipt } from '../sanitize'
import { UPDATE_RECEIPT } from '../graphql/mutations'
import PARTIAL_RECEIPTS from '../partialReceipts.graphql'

const useStyles = makeStyles(theme => ({
  loadingProgress: {
    background: 'none',
    position: 'fixed',
    top: 64,
    left: 0,
    width: 339
  },
  listContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: 340,
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider
  }
}))

export type CubeWithCubeNumber = Cube & {
  cubeNumber: number
}

export type ReceiptWithCubeTestAndCubeWithCubeNumbers = Receipt & {
  cubeTest: CubeTest & { cubes: CubeWithCubeNumber[] }
}

function shouldShowInToday(cube: Cube) {
  if (
    moment(cube.testDate).isBefore(new Date(), 'days')
    && (
      (cube.type === CubeType.Pressure && cube.pressureStrength)
      || (cube.type === CubeType.Penetration && cube.penetration)
      || (cube.type === CubeType.Ripeness && cube.pressureStrength && cube.temperature)
    )
  ) {
    return false
  }
  return true
}

let persistTimeout: any

const PressureStrength: React.FC<{}> = (props) => {
  const history = useHistory()
  const { selectedPlant, setIsSaving, setIsSaved } = useContext(AppContext)
  const { loading, data } = useQuery<{ receipts: Receipt[] }>(PARTIAL_RECEIPTS, { variables: { plantId: selectedPlant?.id, dateType: DateType.TestDate } })
  const [update] = useMutation(UPDATE_RECEIPT)
  const { loadingProgress, listContainer } = useStyles()
  const { cubeHash } = useParams<{ cubeHash: string }>()
  const receiptsWithCubeTestWithCubeNumbers: ReceiptWithCubeTestAndCubeWithCubeNumbers[] = useMemo(() => data?.receipts ? (data.receipts.filter(r => r.cubeTest) as ReceiptWithCubeTest[]).map(receipt => ({ ...receipt, cubeTest: { ...receipt.cubeTest, cubes: (receipt.cubeTest?.cubes || []).map(cube => ({ ...cube, cubeNumber: receipt.cubeTest.cubeNumber })) } })) : [], [data])
  const cubes: CubeWithCubeNumber[] = useMemo(() => receiptsWithCubeTestWithCubeNumbers.reduce((cubes, receipt) => [...cubes, ...receipt.cubeTest.cubes], [] as CubeWithCubeNumber[]).filter(c => c.status === CubeStatus.Active), [receiptsWithCubeTestWithCubeNumbers])
  const selectedCube = useMemo(() => cubes.find(c => c.hash === cubeHash), [cubes, cubeHash])
  const selectedReceipt = useMemo(() => receiptsWithCubeTestWithCubeNumbers.find(r => r.cubeTest?.cubeNumber === selectedCube?.cubeNumber), [receiptsWithCubeTestWithCubeNumbers, selectedCube])
  const cubesByDay = useMemo(() => groupCubesByDay(cubes), [cubes])
  const cubesToday = useMemo(() => cubesByDay.filter(({ date }) => moment(date).isSameOrBefore(new Date(), 'day')).reduce((arr, { cubes }) => [...arr, ...cubes], [] as CubeWithCubeNumber[]).filter(shouldShowInToday).sort((a, b) => new Date(b.testDate).getTime() - new Date(a.testDate).getTime()), [cubesByDay])
  const [dialogProps, confirmDelete] = useState({ open: false } as { open: boolean, onCancel?: () => void, onConfirm?: (cancelRemarks: string) => void })

  const handleSelect = useCallback((cube: CubeWithCubeNumber) => {
    history.push(`/pressure/${cube.hash}`)
  }, [history])

  const handleChange = useCallback((receipt: ReceiptWithCubeTest) => {
    persistTimeout && clearTimeout(persistTimeout)
    persistTimeout = setTimeout(async () => {
      setIsSaving(true)
      await update({ variables: { receipt: sanitizeReceipt(receipt), receiptId: receipt.id } })
      setIsSaving(false)
      setIsSaved(true)
      setTimeout(() => setIsSaved(false), 5000)
    }, 1000)
  }, [setIsSaving, setIsSaved, update])

  const handleCancel = useCallback((cube: CubeWithCubeNumber) => {
    confirmDelete({
      open: true,
      onCancel: () => confirmDelete({ open: false }),
      onConfirm: async (cancelRemarks: string) => {
        confirmDelete({ open: false })
        const receipt = receiptsWithCubeTestWithCubeNumbers.find(r => r.cubeTest.cubeNumber === cube.cubeNumber)
        if (receipt) {
          const index = receipt.cubeTest.cubes.findIndex(c => c.hash === cube.hash)
          if (index >= 0) {
            receipt.cubeTest.cubes[index] = { ...cube, status: CubeStatus.Cancelled, cancelRemarks }
            setIsSaving(true)
            await update({ variables: { receipt: sanitizeReceipt(receipt), receiptId: receipt.id } })
            setIsSaving(false)
            setIsSaved(true)
            setTimeout(() => setIsSaved(false), 5000)
          }
        }
      }
    })
  }, [update, receiptsWithCubeTestWithCubeNumbers, setIsSaved, setIsSaving])

  return <Grid container={true} style={{ flex: 1, overflow: 'hidden' }}>
    <div className={listContainer}>
      <List {...cubesToday && cubesToday.length > 0 && {
        subheader: <ListSubheader component="div" disableSticky={true}>Druksterktemetingen vandaag</ListSubheader>
      }} disablePadding={true} style={{ height: '100%', overflow: 'auto' }}>
        {loading && <LinearProgress variant="indeterminate" className={loadingProgress} />}
        {cubesToday && cubesToday.map((cube, k) => (
          <CubeListItem key={k}
            cube={cube}
            receipt={receiptsWithCubeTestWithCubeNumbers.find(({cubeTest}) => cubeTest.cubeNumber === cube.cubeNumber)}
            onSelect={() => handleSelect(cube)}
            onCancel={() => handleCancel(cube)}
            isSelected={selectedCube?.hash === cube.hash}
          />
        ))}
        {cubesToday && cubesToday.length === 0 && (
          <EmptyState Icon={PressureIcon} text="Geen druksterktemetingen vandaag" />
        )}
      </List>
      {cubes && <CubesScheduled cubesByDay={cubesByDay.filter(({ date }) => moment(date).isAfter(new Date(), 'day'))} />}
    </div>
    {loading ? <Loading /> : selectedCube && selectedReceipt ?
      <CubeEdit receipt={selectedReceipt} cube={selectedCube} onChange={handleChange} /> :
      (cubes && cubes.length > 0) ? <EmptyState Icon={PressureIcon} text="Selecteer een druksterktemeting" /> : null
    }
    <CancelCubeDialog {...dialogProps} />
  </Grid>
}

export default PressureStrength

function groupCubesByDay(cubes: CubeWithCubeNumber[]) {
  return cubes.reduce((groups, cube) => {
    const index = groups.findIndex(group => moment(group.date).isSame(cube.testDate, 'day'))
    if (index >= 0) {
      groups[index].cubes.push(cube)
    } else {
      groups.push({ date: cube.testDate, cubes: [cube] })
    }
    return groups.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
  }, [] as Array<{ date: Date, cubes: CubeWithCubeNumber[] }>)
}
