import gql from 'graphql-tag'
export default gql`query environmentClassesQuery {
  environmentClasses {
    code
    description
    minCement
    maxWbf
    requirementMax
    deviationMax
    maxDeviationsAllowed
    minimumAirPercentages {
      largestGrain
      airPercentage
    }
    encroachment
    id
  }
}`