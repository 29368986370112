import gql from 'graphql-tag'
export default gql`query consistencyTestTypeQuery($id: Int!) {
  consistencyTestType(id: $id) {
    code
    description
    parameters {
      unit
      label
      placeholder
    }
    id
  }
}`